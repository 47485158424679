import ApiService from './api.service';

export const MarketAreaService = {
  query(params) {
    return ApiService.query('market_areas', {
      params,
    });
  },
  get(slug) {
    return ApiService.get('market_areas', slug);
  },
  create(params) {
    return ApiService.post('market_areas', { market_area: params });
  },
  update(slug, params) {
    return ApiService.update('market_areas', slug, { market_area: params });
  },
  destroy(slug) {
    return ApiService.delete(`market_areas/${slug}`);
  },
  report(params) {
    return ApiService.post('market_areas/report', { market_area: params });
  },
};

export default MarketAreaService;
