<template>
  <div>
    <div v-if="region">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <div class="page-header">
          <h4 id="top">Editing Regions: <small>{{ region.name }}</small></h4>
        </div>
      </div>

      <!-- Error Alert -->
      <danger-alert :errors="errors"></danger-alert>
      <form v-on:submit.prevent="editRegion">
        <div class="form-group text-left">
          <label for="reg-name">Region Name</label>
          <input
            type="text"
            class="form-control"
            v-model="region.name"
            placeholder="Region Name"
            id="reg-name"
            name="reg-name"
          />
        </div>

        <div class="form-group text-left">
          <label for="reg-name">Region Code</label>
          <input
            type="text"
            class="form-control"
            v-model="region.region_code"
            placeholder="Region Code"
            id="reg-code"
            name="reg-code"
          />
        </div>

        <div class="form-group text-left">
          <label for="market_area_id">Market Areas</label>
          <select class="form-control" v-model="region.market_area_id" id="market_area_id">
            <option v-for="market_area in market_areas" :value="market_area.id" v-bind:key="market_area.id">{{ market_area.name }}</option>
          </select>
        </div>

        <div class="form-group text-left">
          <label for="region-archived">Is Archived?</label>
          <b-checkbox id="region-archived" v-model="region.is_archived"/>
        </div>

        <div class="form-group float-right">
          <div class="btn-group">
            <router-link :to="{ name: 'RegionShow', params: { id: region.id } }" tag="a" role="button" class="btn btn-secondary">Back</router-link>
            <input type="submit" class="btn btn-success" value="Update Region" />
          </div>
        </div>
      </form>
    </div>
    <loading-overlay v-else></loading-overlay>
  </div>
</template>

<script>

import { RegionService } from '@/common/services/region.service';
import { MarketAreaService } from '@/common/services/market_area.service';
import DangerAlert from '@/common/components/danger-alert.vue';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'RegionEdit',
  components: {
    DangerAlert,
    LoadingOverlay,
  },
  data() {
    return {
      region: null,
      errors: [],
      market_areas: null,
    };
  },
  created() {
    RegionService.get(this.$route.params.id)
      .then(({ data }) => {
        this.region = data;
      });

    this.getMarketAreas();
  },
  methods: {
    editRegion() {
      const payload = {
        name: this.region.name,
        region_code: this.region.region_code,
        market_area_id: this.region.market_area_id,
        is_archived: this.region.is_archived,
      };
      RegionService.update(this.$route.params.id, payload)
        .then(({ data }) => {
          this.$router.push({ name: 'RegionShow', params: { id: this.region.id } });
          this.$notify({
            title: 'Edited Region',
            text: `Successfully edited ${data.name}!`,
            type: 'success',
            width: 350,
          });
        })
        .catch((err) => {
          this.errors = err.response.data.error;
          this.$notify({
            title: 'Could not submit form!',
            text: this.errors.join(' '),
            type: 'error',
            width: 350,
          });
        });
    },
    getMarketAreas() {
      MarketAreaService.query(1, 1000).then(({ data }) => {
        this.market_areas = data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/regions";
</style>
